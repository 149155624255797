.ckEditorMain sq-editor {
  overflow: hidden;

  &.journalOnly {
    overflow: inherit;
  }

  .flexRowContainer.flexFill.flexBasisZero .ck-editor__editable {
    width: 100%;
    min-height: 100%;
    padding: 16px;
    margin: 0px;
  }

  .editorContainer {
    background: $white;
    overflow: auto;
    height: 100%;
  }

  .ck-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    min-height: 100%;

    table {
      table-layout: fixed;
      //CKeditor bug, I had to force the vertical align because the styling conflicts with boostrap
      td {
        vertical-align: middle;
      }
    }

    img {
      max-width: 100%;
    }
  }

  // Base Seeq customizations for the CK editor.
  .ck-toolbar.ck-rounded-corners {
    // disable the thick border and rounded corners on the top of the editor
    border-top: 0;
    border-radius: 0px;
    background: white;
    position: relative;

    .ck.ck-toolbar__grouped-dropdown:last-child {
      position: initial;
    }

    .ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible {
      width: auto;
    }

    .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
      width: 85px;
    }

    .ck-toolbar__items :not(.ck-toolbar__line-break),
    .ck.ck-toolbar__grouped-dropdown {
      margin-top: 0;
      margin-bottom: 0;

      .ck-button {
        &.ck-dropdown__button.contentBtn {
          min-height: 24px;
          min-width: 24px;
          padding: 0 6px;

          svg {
            height: 14px;
            width: 14px;
            margin: 0;
          }
        }

        i, svg.ck-icon {
          height: 22px;
        }
      }
    }
  }
}


.ckEditorMain.fixedWidth sq-editor {
  @media screen {
    .editorContainer {
      padding: 40px 0;
      height: 100%;
      background-color: #fafafa;

      .ck-content {
        background: white;
        border-radius: var(--ck-border-radius);
        border: 1px #D3D3D3 solid;
        margin: 0 auto;
        min-height: 11in;
        overflow-x: visible;
        overflow: visible;
        padding: .5in;
        width: 8.5in;

        div.page-break {
          background: #fafafa;
          border-bottom: 1px #D3D3D3 solid;
          border-top: 1px #D3D3D3 solid;
          left: calc(-0.5in - 1px);
          margin: 40px 0;
          width: 8.5in;
        }

        &.ck-focused {
          border-color: #333333;
          box-shadow: none;

          div.page-break {
            border-color: #333333;
          }
        }
      }
    }
  }

  @media print {
    .editorContainer {
      background: $white
    }
  }
}


.editorBtn {
  height: 30px;
  padding: 4px;
  width: 30px;
  margin: 2px;
  border-radius: 5px;

  &.contentBtn {
    background-color: $sq-link-color-topic !important;
    color: white;
  }

  &:hover {
    background-color: $sq-dark-gray;
  }
}

.ck.ck-button.contentBtn {
  background-color: $sq-link-color-analysis !important; // always use analysis color
  color: white;
  border-radius: 5px !important;

  &:hover {
    cursor: pointer;
  }
}

.ckEditorDropdownButton .ck-dropdown__panel {
  border-radius: 6px !important;
}

.contentWrapper {
  text-decoration: none;

  &.noCopy {
    // CK treats copying a piece of content and its text as copying two instances of the content
    user-select: none;
  }
}

.contentWrapper table {
  margin: 0 !important;
}

.contentWrapper td.nowrap {
  white-space: normal;
}

.inlineBlock.seeqContentWrapper.ck-widget {
  vertical-align: bottom;
}

.dateRangePopover .arrow {
  display: none;
}