$timebar-control-height: 32px;
$border-stroke-width: 2px;
$border-color: $sq-darkest-gray;
$selector-border-color: $sq-darkest-gray;
$selector-fill-color: white;
$selector-outer-color: #EAF3F4;
$text-color: $sq-side-panel-text-color;

.timebar {
  flex: 1 1 auto;
  margin: 0;
  height: $timebar-control-height;
  margin-bottom: 3px;
  > svg {
    width: 100%;
    overflow: visible;

    rect.outer {
      stroke-width: $border-stroke-width;
    }

    g.selector {
      cursor: pointer;
      rect {
        &.selector, &.leftSelector, &.rightSelector {
          stroke-width: $border-stroke-width;
          fill: transparent;
        }
        &.leftSelector, &.rightSelector {
          cursor: ew-resize;
        }
      }
    }
    g.axis {
      width: 100%;
      text {
        font-size: 8pt;
        user-select: none;
      }
      path {
        /* Hide d3 horizontal scale line and end ticks */
        display: none;
      }
    }
  }
}
