/**
This file contains only theme color specific css styling information.
If it's a shade of green or blue it should be added to this file.
No other css information should be added here.
**/

.fc-report.workbenchDisplay {
  color: white !important;
  background-color: $sq-report-color;
}

.fc-report-lock.workbenchDisplay {
  color: $sq-report-color !important;
}

.fc-analysis.workbenchDisplay {
  color: white !important;
  background-color: $sq-analysis-color;
}

.fc-analysis-lock.workbenchDisplay {
  color: $sq-analysis-color !important;
}

.fc-seeq-datalab.workbenchDisplay {
  color: white !important;
  background-color: $sq-datalab-color;
}

.fc-seeq-datalab-lock.workbenchDisplay {
  color: $sq-datalab-color !important;
}

.fc-folder.workbenchDisplay, .fc-folder-lock.workbenchDisplay {
  color: $sq-dark-primary-topic !important;
}

@each $theme, $themeColorDef in $themeColors {

  .color_#{$theme} {

    .sq-text-info {
      color: #{map-get($themeColorDef, link)};
    }

    .sq-text-primary, .sq-icon-theme {
      color: #{map-get($themeColorDef, dark)};
    }

    .sq-icon-theme-light {
      color: #{map-get($themeColorDef, light)};
    }

    a {
      color: #{map-get($themeColorDef, link)};
    }

    .overlayPanel {
      box-shadow: 0 0 2px 2px  #{map-get($themeColorDef, darker)};
    }

    .force-color {
      color: #{map-get($themeColorDef, dark)} !important;
    }

    .sq-bg-theme {
      background-color: #{map-get($themeColorDef, darker)};
    }

    // Bootstrap 4 has defaults for links without href/tabindex. Use these classes to override that behavior.
    // force-link-look also adds text-decoration: underline
    .force-link-color, .force-link-look {
      color: #{map-get($themeColorDef, link)} !important;
    }

    .sq-btn-theme {
      color: $white;
      border-color: #{map-get($themeColorDef, dark)};
      background-color: #{map-get($themeColorDef, dark)};

      &:focus, &:hover {
        border-color: #{map-get($themeColorDef, icon)};
        background-color: #{map-get($themeColorDef, icon)};
      }

      &:active, &:active:focus, &:active:hover {
        background-color: (lighten#{map-get($themeColorDef, icon)}, 3%);
        border-color: #{map-get($themeColorDef, dark)};
      }
    }

    .sq-btn-outline {
      &:active, &:active:focus, &:active:hover {
        border-color: #{map-get($themeColorDef, dark)};
      }
    }

    .btn-primary {
      background-color: #{map-get($themeColorDef, link)};
      border-color: #{map-get($themeColorDef, link)};

      &:hover {
        background-color: #{map-get($themeColorDef, icon)} !important;
        border-color: #{map-get($themeColorDef, icon)};
      }

      &:focus {
        background-color: #{map-get($themeColorDef, icon)} !important;
        border-color: #{map-get($themeColorDef, icon)};
      }
    }

    /******************* Report Editor *******************/
    .reportDateModal {
      sq-display-range-selector {
        .btn-link > i {
          color: #{map-get($themeColorDef, link)};

          &:hover {
            color: #{map-get($themeColorDef, dark)};
          }
        }

        #conditionEditor {
          ul.dropdown-menu {
            li.active > a {
              &, &:hover, &:focus {
                background: #{map-get($themeColorDef, link)};
              }
            }
          }
        }
      }
    }

    .form-control:focus, .form-control:hover, input:focus, input.text:focus, input:hover, input.text:hover {
      border-color: #{map-get($themeColorDef, dark)};
    }

    .btn-default {
      &:active {
        border-color: #{map-get($themeColorDef, dark)} !important;
      }
    }

    .splashScreen {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .fa-header-color {
      color: #{map-get($themeColorDef, dark)};
    }

    @media screen {
      hr.fr-page-break {
        border-top: dashed #{map-get($themeColorDef, dark)} 1px;
      }

      hr.fr-page-break:after {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    div.reportBackupPreview {
      .backArrow {
        color: #{map-get($themeColorDef, dark)};

        &:hover {
          color: #{map-get($themeColorDef, icon)};
        }
      }

      .dateDisplay {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .textPrimaryMenuItem {
      &:hover {
        &, & > a {
          background-color: #{map-get($themeColorDef,dark)}
        }
      }
    }

    .sq-btn-default {
      @extend .btn-default;

      &, &:active, &:focus, &:hover, &:active:focus, &:active:hover {
        color: #{map-get($themeColorDef, dark)};
        border-color: #{map-get($themeColorDef, darker)};
      }

      &:hover, &:focus {
        border-color: #{map-get($themeColorDef, icon)};
        background-color: #{map-get($themeColorDef, icon)};
        color: $white;
      }

      &:active:hover, &:active:focus {
        background-color: #{map-get($themeColorDef, icon)};
        color: $white;
      }
    }

    .sq-icon-hover {
      &:hover, &:focus {
        i {
          color: #{map-get($themeColorDef, icon)};
        }
      }
    }

    .reportBackups {
      div[uib-accordion-group] {
        .panel-heading {
          h4.panel-title {
            &:hover {
              color: #{map-get($themeColorDef, icon)};
            }
          }
        }

        .panel-body {
          .versionEntry {
            &.selected {
              background-color: #{map-get($themeColorDef, icon)};
            }
          }
        }
      }
    }

    .linkHighlight {
      &:hover, &:focus {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .resizablePanel {
      .handle-right {
        &:hover, &:active, &:focus {
          > i {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }

        i {
          &:hover {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }
      }

      .handle-bottom {
        &:hover, &:active, &:focus {
          > i {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }

        i {
          &:hover {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }
      }
    }

    sq-license-expiration-warning {
      .closeIcon {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .license-management {

      .title {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .ui-grid-top-panel {
      color: #{map-get($themeColorDef, dark)};
    }

    .toolsSearchBar {
      &.active {
        border-bottom: 2px solid #{map-get($themeColorDef, icon)};
      }
    }

    .viewing-badge {
      &:hover {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .file-drop-zone-over {
      border: dashed 2px #{map-get($themeColorDef, dark)};
    }

    .user-admin, .group-admin {
      .modal-header {
        .modal-title {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      i {
        color: #{map-get($themeColorDef, dark)};
      }

      .control-label {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    #formulaContainer {
      .highlightedText {
        color: #{map-get($themeColorDef, dark)};
      }

      .rg-right {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      .rg-bottom {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      .formulaHelp {

        h1, h2, h3 {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    sq-chart {
      .highcharts-hover-x-label {
        text {
          fill: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .worksheetViewSelector {
      background-color: #{map-get($themeColorDef, link)};

      &:hover {
        background-color: #{map-get($themeColorDef, icon)};
      }
    }

    .overlayPanel {
      form {
        div:not(.has-feedback) .control-label {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .loginSlogan {
      color: #{map-get($themeColorDef, dark)};
    }

    sq-investigate-range-selector, sq-display-range-selector {
      sq-date-time-entry, sq-duration-entry {
        color: #{map-get($themeColorDef, dark)};;
      }
    }

    .folderAction {
      &:hover {
        color: #{map-get($themeColorDef, icon)};
      }
    }

    .btn-link {
      color: #{map-get($themeColorDef, link)};

      &:hover {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .dropdown-menu > li > a {
      &:hover,
      &:focus {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .dropdown-menu > .active > a {
      color: #{map-get($themeColorDef, dark)};
    }

    sq-user-profile.overlayPanel {
      box-shadow: 0 0 2px 2px rgba(map-get($themeColorDef, dark), 0.5);
    }

    .iconHover {
      &:hover {
        color: #{map-get($themeColorDef, icon)};
      }
    }

    .mainHeader {
      background-color: #{map-get($themeColorDef, dark)};
      color: #{map-get($themeColorDef, light)};

      a {
        color: #{map-get($themeColorDef, light)};
      }

      .sq-navbar-default {

        &:hover {
          // Use rollover text on hover
          background-color: #{map-get($themeColorDef, dark)};
          color: #{map-get($themeColorDef, icon)};
        }

        .sq-navbar-noninteractable {
          background-color: #{map-get($themeColorDef, dark)};
        }
      }

      .navbarHomeButton {
        background-color: #{map-get($themeColorDef, darker)};

        &:hover {
          color: #{map-get($themeColorDef, icon)};
        }
      }
    }

    sq-user-profile.overlayPanel {

      h4 {
        color: #{map-get($themeColorDef, dark)};;
      }

      div:not(.has-feedback) .control-label {
        color: #{map-get($themeColorDef, dark)};;
      }
    }

    .mainFooter {
      color: #{map-get($themeColorDef, darker)};

      .browserRecommendation {
        color: #{map-get($themeColorDef, darker)};

        & a, & a:hover {
          color: #{map-get($themeColorDef, dark)};
          text-decoration: underline;
        }
      }
    }

    .workstepButton {
      &:not([disabled=disabled]) {
        color: #{map-get($themeColorDef, light)};
      }

      &:hover:not([disabled=disabled]) {
        color: #{map-get($themeColorDef, icon)};
      }

      &:active:not([disabled=disabled]) {
        color: #{map-get($themeColorDef, icon)} !important;
      }
    }

    .worksheetWell {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .btn-transparent {
      color: #{map-get($themeColorDef, link)};

      &:hover, &:active {
        background: #{map-get($themeColorDef, dark)};
      }
    }

    .worksheetsColumn {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .borderBottomPrimary {
      border-bottom: 1px solid #{map-get($themeColorDef, dark)};
    }

    sq-bottom-panels {
      .bottomPanel {
        .header {
          background: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .workbookAcl {
      ul.dropdown-menu {

        li.active > a {
          &, &:hover, &:focus {
            background: #{map-get($themeColorDef, dark)};
          }
        }
      }
    }

    .browseColumn {
      .icon {
        color: #{map-get($themeColorDef, dark)};
      }

      .toolbar .option .btn:hover, .toolbar .option .btn .active, .toolPanel {
        .icon {
          color: #{map-get($themeColorDef, icon)};
        }
      }

      .toolbar {
        .btn-default {
          color: #{map-get($themeColorDef, dark)};

          &:hover {
            border-color: #{map-get($themeColorDef, icon)};
          }
        }
      }

      .toolOverviewCard {
        .headerName {
          color: #{map-get($themeColorDef, dark)};
        }

        .headerIcon {
          color: #{map-get($themeColorDef, dark)};
        }

        &:hover, &:focus {
          .tooltipIcon {
            color: #{map-get($themeColorDef, icon)};
          }
        }
      }

      .nav-tabs.nav-justified > li {
        &:not(.disabled):not(.active) {
          & > a {
            box-shadow: 6 px - 3 px 18 px - 6 px rgba(map-get($themeColorDef, dark), 1);
          }
        }
      }

      .panel {
        box-shadow: 0 0 1px 1px rgba(map-get($themeColorDef, dark), 0.36);

        h4, .h4 {
          color: #{map-get($themeColorDef, dark)};
        }

        sq-check-mark-or-number {
          .fa-check, .fa-plus-square, .fa-minus-square {
            color: #{map-get($themeColorDef, dark)};
          }
        }
      }

      .panel-primary {
        border-color: #{map-get($themeColorDef, dark)};
      }
    }

    .btn-toolbar {
      &:hover {
        border-color: #{map-get($themeColorDef, darker)};
        color: #{map-get($themeColorDef, darker)};
      }

      & .active {
        border-color: #{map-get($themeColorDef, darker)};
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .licenseUploadIcon {
      color: #{map-get($themeColorDef, dark)};
    }

    .searchTitleInput {
      & input {
        color: #{map-get($themeColorDef, dark)};

        &:focus, &:hover {
          border-color: #{map-get($themeColorDef, dark)};
          box-shadow: inset 0 1px 1px rgba($sq-darkest-gray, 0.075), 0 0 8px rgba(map-get($themeColorDef, dark), 0.6);
        }
      }
    }

    .worksheetEditingOverlay {
      color: #{map-get($themeColorDef, icon)};
    }

    .workbookSlide {
      .workbookLabel {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .workbookDetail {
      background: #{map-get($themeColorDef, dark)};

      .fc-edit:hover, .fa-trash:hover, .fc-share:hover, .fa-plus-circle:hover {
        color: #{map-get($themeColorDef, icon)};
      }

      .worksheetContainer {
        &:hover {
          background: #{map-get($themeColorDef, darker)};
        }
      }

      .btn-primary {
        color: #{map-get($themeColorDef, dark)};

        &:hover {
          background-color: #{map-get($themeColorDef, icon)} !important;
        }
      }
    }

    .itemRow {
      .searchBtn {
        color: #{map-get($themeColorDef, link)};

        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    sq-new-workbench-item {
      .h4 {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .activeOnHover:hover {
      color: #{map-get($themeColorDef, dark)};
    }

    .timebar {
      > svg {
        g.selector {
          rect {
            &.selector, &.leftSelector, &.rightSelector {
              stroke: #{map-get($themeColorDef, dark)};
            }
          }
        }
      }
    }

    .toolsSearchBar {
      &.active {
        border-bottom: 2px solid #{map-get($themeColorDef, dark)};
      }
    }

    .btn-inversed {
      @extend .btn-default;
      background-color: #{map-get($themeColorDef, dark)};
    }

    .btn-inversed:hover {
      background-color: #{map-get($themeColorDef, icon)};
    }

    .panel-primary > .panel-heading {
      border-color: #{map-get($themeColorDef, dark)};
    }

    .alert-info {
      border-color: #{map-get($themeColorDef, dark)};
    }

    .form-control {
      box-shadow: none;

      &:focus {
        box-shadow: inset 0 1px 2px 0 rgba(map-get($themeColorDef, dark), 0.075) !important;
      }

    }

    ul.searchBreadcrumbs {
      li {
        color: #{map-get($themeColorDef, link)};
      }
    }

    .folderAction, .text-interactive {
      color: #{map-get($themeColorDef, link)};

      &:focus, &:hover, &:active {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    input[type="checkbox"]:focus + label::before {
      outline: #{map-get($themeColorDef, dark)} solid 1px;
    }

    input[type="radio"]:focus + label::before {
      outline: #{map-get($themeColorDef, dark)} solid 1px;
    }

    .hoverBorder:hover, .focusBorder:focus {
      border: 1px solid #{map-get($themeColorDef, dark)} !important;
    }

    .modal-body .optionSelected {
      background-color: #{map-get($themeColorDef, link)};
    }

    #aclGrid {
      .ui-grid-header-cell {
        i {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .newBtnWorkbench {
      .workbookIconLarge {
        color: #{map-get($themeColorDef, dark)};
      }

      .h4 {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .homeScreenNav.current {
      border-left: 5px solid #{map-get($themeColorDef, dark)};
      background-color: #{map-get($themeColorDef, highlight)};
    }

    .homeScreenBreadcrumbLink, .breadcrumbLink {
      color: #{map-get($themeColorDef, dark)};
    }

    .assetGroupHeader {
      background-color: #{map-get($themeColorDef, highlight)};
      border-bottom: 1px solid #{map-get($themeColorDef, dark)};
    }

    .selected-item-entry {
      background-color: #{map-get($themeColorDef, dark)} !important;
      color: white;

      > i, .itemAsset {
        color: white !important;
      }
    }

    .react-select__control {
      border-color: $sq-disabled-gray;

      &:focus, &:active, &:hover {
        border-color: #{map-get($themeColorDef, dark)} !important;
        background-color: $sq-light-gray !important;
      }
    }

    .react-select__option {
      background-color: $white !important;

      &:focus, &:active, &:hover {
        border-color: #{map-get($themeColorDef, dark)} !important;
        background-color: $sq-light-gray !important;
      }
    }

    .react-select__option--is-focused {
      background-color: $sq-light-gray !important;
    }

    .react-select__option--is-selected:not(.image-select) {
      background-color: #{map-get($themeColorDef, dark)} !important;
      color: white;

      &:focus, &:active, &:hover {
        border-color: #{map-get($themeColorDef, dark)} !important;
        background-color: #{map-get($themeColorDef, dark)} !important;
      }

      > i, i {
        color: white !important;
      }
    }

    .react-select-text-entry__control {
      border-color: $sq-disabled-gray;
      border-top-right-radius: 0;

      &:focus, &:active, &:hover {
        border-color: #{map-get($themeColorDef, dark)} !important;
        background-color: $sq-light-gray !important;
      }
    }

    .react-multiple-select__control {
      border-color: $sq-disabled-gray;

      &:focus, &:active, &:hover {
        border-color: #{map-get($themeColorDef, dark)} !important;
      }
    }

    .alert-info-hover:hover {
      background-color: (darken#{map-get($themeColorDef, higlight)}, 5%);
    }

    .dropdown-menu > a.dropdown-item {
      &:focus, &:active, &:hover {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .homeScreenHelpfulLink {
      &:hover {
        background-color: #{map-get($themeColorDef, highlight)};
      }
    }

    .CircularProgressbar-path {
      stroke: #{map-get($themeColorDef, dark)} !important;
    }

    .sq-slider::-moz-range-progress {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .sq-slider::-ms-fill-lower {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .sq-slider::-ms-thumb {
      &:hover, &:focus, &:active {
        background: #{map-get($themeColorDef, darker)};
      }
    }

    .sq-slider::-webkit-slider-thumb {
      background: #{map-get($themeColorDef, dark)};
    }

    .tourCarousel {
      .carousel-indicators {
        li.active {
          background-color: #{map-get($themeColorDef, dark)};
        }
      }
    }
  }
}
