/* Helpers for manipulating text */
.unselectable {
  user-select: none;
}

.xsmall {
  line-height: 90%;
  font-size: 75%;
}

.tightLines {
  line-height: 1.1em;
}

.link-no-underline {
  &, &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.link-underline-hover {
  text-decoration: none;

  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

.link-no-focus:focus {
  outline: none;
}

.cursorPointer {
  cursor: pointer;
}

.cursorText {
  cursor: text;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.cursorResize {
  cursor: ns-resize;
}

.cursorResizeEW {
  cursor: ew-resize;
}

.cursorAuto {
  cursor: auto;
}

.cursorDefault {
  cursor: default;
}

/* Recommended workaround for style between Angular and Bootstrap */
.nav,
.pagination,
.carousel,
.a {
  cursor: pointer;
}

.aggressiveWordBreak {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
}

.simple-word-break {
  word-wrap: break-word;
  white-space: normal;
}

.breakWord {
  word-wrap: break-word;
}

.nowrap {
  white-space: nowrap;
}

textarea.input-code {
  white-space: pre;
  word-wrap: normal;
  overflow: auto;
}

.text-strike {
  text-decoration: line-through;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline !important;
}

.text-underline-onhover:hover {
  text-decoration: underline;
}

.text-opaque-onhover {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.text-opaque {
  opacity: 1 !important;
}

.text-half-opacity {
  opacity: 0.5;
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder;
}

.text-not-bold {
  font-weight: normal;
}

.text-monospace {
  font-family: $font-family-monospace;

  span {
    font-family: $font-family-monospace;
  }
}

.text-valign-middle, .table > tbody > tr > td.text-valign-middle {
  vertical-align: middle;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.emphasis {
  @extend .text-bolder;
  @extend .text-italic;
}

.fa-rotate-135 {
  transform: rotate(135deg);
}

@each $num in (5 8 9 10 11 12 13 14 15 16 17 18 30) {
  .fs#{$num} {
    font-size: #{$num}px;
  }

  .fs#{$num}vw {
    font-size: #{$num}vw;
  }

  .fs#{$num}vh {
    font-size: #{$num}vh;
  }
}

.text-center {
  text-align: center;
}

.font-size-smaller {
  font-size: smaller;
}

.font-size-xlarge {
  font-size: x-large;
}

.font-size-larger {
  font-size: larger;
}

.font-size-large {
  font-size: large;
}

.activeOnHover:hover {
  color: $link-color;
}

.force-link-look {
  text-decoration: underline !important;
}

.error-text {
  color: $sq-danger-color;
}

.textOverflowEllipsis {
  text-overflow: ellipsis;
}
