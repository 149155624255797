.customOverlay {
  position: absolute;
  z-index: 250;
  border: 1px solid #E2E2E2;

  &.tableViewer {
    background: #FFFFFF;
    border-radius: 5px;
    top: 20px;
  }
  
  .header {
    font-size: 14px;
    background-color: #007960;
    color: #FFFFFF;
    text-align: center;
    padding: 3px;
    cursor: move;
    border-radius: 5px 5px 0 0;
    display: flex;

    .fillAll {
      flex-grow: 1;
    }

    .alignRight {
      flex-grow: 0;
      align-self: flex-end;
    }

    .settingsButton {
      cursor: pointer;
    }
  }
  
  .content {
    width: 600px;
    padding: 10px;
  }

  .settingsContainer {
    width: 300px;
    padding: 10px;
  }
}

.noMouse {
  pointer-events: none;
}
