@import 'bootstrap4_variable_overrides';
@import 'bootstrap4';
@import 'variable_overrides';
@import 'bootstrap';
@import 'font_custom_imports';
@import 'font_custom';
@import 'font_custom_support';
@import '~font-awesome/scss/font-awesome';
@import 'source_sans_pro';
@import 'custom_variables';
@import 'layout';
@import 'text';
@import 'components';
@import 'formula_editor';
@import 'timebar';
@import 'editor';
@import '~jquery-minicolors/jquery.minicolors.css';
@import '~froala-editor/css/froala_editor.pkgd.min.css';
@import '~react-circular-progressbar/dist/styles.css';
@import 'baseColors';
@import 'themeColors';
@import 'froala/analysis-theme';
@import 'froala/topic-theme';
@import 'bootstrap4_components';
@import 'ckeditor';
@import 'tableViewer';
@import 'dateRangeLabel';

.mainHeader {
  min-height: 30px;

  .sq-navbar-default {
    &:hover {
      // Use rollover text on hover
      cursor: pointer;
    }
  }
}

// force chrome & safari to always show a scrollbar
// this ensures that the user knows when there is extra content in a pane and
// prevents the scrollbar from covering up content while the user is scrolling
::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}

.mainFooter {
  padding: 4px 10px;
}

.navbar-brand {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  cursor: default;
}

/* Used to override cursor when dragging vertical scale on Trend */
.globalCursorNorthSouth * {
  cursor: ns-resize !important;
}

/* Used to override cursor when dragging horizontal scale on Trend */
.globalCursorEastWest * {
  cursor: ew-resize !important;
}

/* Used to override cursor when dragging timebar */
.globalCursorPointer * {
  cursor: pointer !important;
}

.as-sortable-dragging {
  overflow: hidden; // Prevents scrollbars when dragging an element
}

.tourInterceptor {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%
}

header h4 {
  font-weight: normal;
  text-align: center;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none;
}

.hide-ms-clear {
  &::-ms-clear {
    width: 0;
    height: 0;
  }
}

.striped {
  background-color: $sq-lightest-gray;
}
